let spinnerHtmlElements: HTMLDivElement | null = null;
let spinnerCssLinkElement: HTMLLinkElement | null = null;

interface EventData {
  source?: string;
  type?: string;
}

function addLoadingSpinnerCssLinkElement(): void {
  if (spinnerCssLinkElement) {
    return;
  }
  spinnerCssLinkElement = document.createElement('link');
  spinnerCssLinkElement.href = '/loading-spinner.css';
  spinnerCssLinkElement.rel = 'stylesheet';
  document.head.appendChild(spinnerCssLinkElement);
}

function addLoadingSpinnerHtmlElements(): void {
  if (spinnerHtmlElements) {
    return;
  }
  spinnerHtmlElements = document.createElement('div');
  spinnerHtmlElements.id = 'loading-spinner';
  spinnerHtmlElements.className = 'background';
  spinnerHtmlElements.innerHTML = `
    <img class="huveLogo" src="/assets/react-images/huvepharma-logo.svg">
    <div class="container">
      <div class="spinner"></div>
    </div>
  `;
  document.body.appendChild(spinnerHtmlElements);
}

export function setUpSpinnerMessageListener(): void {
  window.addEventListener(
    'message',
    (event: MessageEvent<EventData>) => {
      if (event.data.source === 'huve-app') {
        if (event.data.type === 'hide-loading-spinner') {
          hideLoadingSpinner();
        }
        if (event.data.type === 'show-loading-spinner') {
          showLoadingSpinner();
        }
      }
    },
    false,
  );
}

export function showLoadingSpinner(): void {
  addLoadingSpinnerCssLinkElement();
  addLoadingSpinnerHtmlElements();
}

export function hideLoadingSpinner(): void {
  if (spinnerHtmlElements) {
    spinnerHtmlElements.remove();
    spinnerHtmlElements = null;
  }
  if (spinnerCssLinkElement) {
    spinnerCssLinkElement.remove();
    spinnerCssLinkElement = null;
  }
}

import { Application, registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout';

import {
  setUpSpinnerMessageListener,
  showLoadingSpinner,
} from './loading-spinner';
import microfrontendLayout from './microfrontend-layout.html';

const reactRoutes = [
  'react',
  'photo-upload',
  'about',
  'page-not-found',
  'forgot-password',
  'login',
  'password-reset',
];

setUpSpinnerMessageListener();
showLoadingSpinner();

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp: async ({ name }): Promise<Application> => {
    return await System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((app) => {
  const isReact = (location: Location): boolean => {
    const pathname = location.pathname;
    return reactRoutes.some((route) => pathname.includes(route));
  };

  if (app.name === '@huvepharma/aviapp-react-common') {
    registerApplication({
      ...app,
      activeWhen: (location) => isReact(location),
    });
  } else if (app.name === '@huvepharma/aviapp-common') {
    registerApplication({
      ...app,
      activeWhen: (location) => !isReact(location),
    });
  }
});

layoutEngine.activate();
start({
  urlRerouteOnly: true,
});
